import React from "react"
import "../assets/scss/main.scss"
import Header from "./Header"
import Footer from "./Footer"

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div id="site-wrapper">
        <Header />
        {children}
        <Footer />
      </div>
    )
  }
}

export default Template
