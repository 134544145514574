import React from "react"
import Container from "./Container"

import Instagram from "../assets/images/icon-instagram"

const Footer = () => {
  return (
    <footer id="footer">
      <Container>
        <ul className="social">
          <li>
            <a
              href="https://www.instagram.com/beckyfart/"
              target="_blank"
              rel="noopener noreferrer"
              title="Becky's Instagram"
            >
              <Instagram />
            </a>
          </li>
        </ul>
        <div className="copyright">
          <p>Built by{" "}
            <a
              href="https://connorpote.co.uk"
              target="_blank"
              rel="noopener noreferrer"
              title="Connor Pote, front end developer"
            >
              Connor Pote
            </a>. © Becky Fitzgerald {new Date().getFullYear()}
          </p>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
