import React from "react"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"

import CloseIcon from "../assets/images/icon-close_white.svg"
// import Logo from "../assets/images/logo.inline.svg"
import Logo from "../assets/images/becky.inline.svg"

import Burger from "../assets/images/icon-burger"

class Header extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }
  render() {
    return (
      <header id="page-header">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <Link to="/">
                {/* <img src={Logo} alt="Becky Fitzgerald - Graphic Designer logo"/> */}
                <Logo />
              </Link>
            </div>
            <nav role="navigation" id="main-menu">
              <ul>
                <li>
                  <Link to="/" activeClassName="active">
                    Work
                  </Link>
                </li>
                <li>
                  <Link to="/about/" activeClassName="active">
                    About
                  </Link>
                </li>

                <li>
                  <Link to="/contact/" activeClassName="active">
                    Contact
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/beckyfart/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </nav>

            <Menu
              pageWrapId={"page-header"}
              outerContainerId={"site-wrapper"}
              right
              id={"mobile-menu"}
              burgerButtonClassName={"burger-button"}
              customCrossIcon={<img src={CloseIcon} alt="Close Menu" />}
              customBurgerIcon={<Burger />}
              bodyClassName={"active-menu"}
              disableAutoFocus
            >
              <Link className="menu-item" to="/" activeClassName="active">
                Work
              </Link>
              <Link
                className="menu-item"
                to="/about"
                activeClassName="active"
                partiallyActive={true}
              >
                About
              </Link>

              <Link
                className="menu-item"
                to="/contact"
                activeClassName="active"
                partiallyActive={true}
              >
                Contact
              </Link>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </Menu>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
